<template>
  <b-row>
    <b-col cols="12">

      <b-table
          :fields="fields"
          :items="underReviewLocums"

          ref="table"
          class="mobile_table_css"
          responsive
          @row-clicked="navigateApplierProfile"
      >
      </b-table>

    </b-col>

<!--    <b-col cols="12" v-if="pagination.totalRows ===0" class="text-center">-->
<!--      <span >No data for preview</span>-->
<!--    </b-col>-->
    <!-- Pagination -->

<!--    <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">-->
<!--      <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>-->
<!--    </b-col>-->

    <!-- Pagination -->

<!--    <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">-->

<!--      <b-pagination-->
<!--          v-model="currentPage"-->
<!--          :per-page="pagination.perPage"-->
<!--          :total-rows="pagination.totalRows"-->
<!--          class="mb-0 mt-1 mt-sm-0 "-->
<!--          first-number-->
<!--          last-number-->
<!--          next-class="next-item"-->
<!--          prev-class="prev-item">-->

<!--        <template #prev-text>-->
<!--          <feather-icon icon="ChevronLeftIcon" size="18"/>-->
<!--        </template>-->

<!--        <template #next-text>-->
<!--          <feather-icon icon="ChevronRightIcon" size="18"/>-->
<!--        </template>-->

<!--      </b-pagination>-->

<!--    </b-col>-->


  </b-row>
</template>

<script>
import ApiLocum from '@/apis/modules/locums.js'
import {
  BRow,
  BCol,
  BTable,
  BPagination
} from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination
  },
  data() {
    return {
      underReviewLocums : [],
      fields: [
        { key: "user.first_name", label: "Name" },
        { key: "address", label: "Address" },
        { key: "user.job_role", label: "Role" },
        { key: "price", label: "Price" },
      ],

      requests :[
        {
          id: 1,
          role: 'Nurse',
          name: "Dickerson",
          address: "US",
          price: "$10",

        }
      ],
      currentPage:1
    }
  },

  methods: {
    navigateApplierProfile(item) {
      this.$router.push(`/applier-profile/${item.id}`)
    },
   async getLocums () {
       try {
        this.underReviewLocums = (await ApiLocum.getLocums('under review')).data.data
         console.log(this.underReviewLocums)
       }catch (e) {

       }
     }
   },
   async mounted () {
     await this.getLocums()
  }
}
</script>




