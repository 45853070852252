<template>
  <div class="home">


  <b-card class="mp-0">
<!--    <b-col class="mb-2" cols="12" md="4">-->
<!--      <b-input-group>-->
<!--        <b-input-group-prepend is-text>-->

<!--          <feather-icon icon="SearchIcon"/>-->
<!--        </b-input-group-prepend>-->

<!--        <b-form-input v-model="filters['full_name']"-->
<!--                      placeholder="Search"/>-->
<!--      </b-input-group>-->
<!--    </b-col>-->
    <b-tabs lazy>
      <b-tab title="Pending Requests" >
        <div class="alert-body"><PendingRequest /> </div>
      </b-tab>


      <b-tab title="Approved Requests" >
        <div class="alert-body"><ApprovedRequest /> </div>
      </b-tab>


      <b-tab title="Declined Requests" >
        <div class="alert-body"><DeclinedRequest /> </div>
      </b-tab>


    </b-tabs>
<!--    </b-row>-->
  </b-card>
  </div>
</template>

<script>

import ApprovedRequest from './view/approvedrequest'
import DeclinedRequest from './view/declinedrequest'
import PendingRequest from './view/pendingrequest'

import { BCard, BTabs , BTab, BButton, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
  name: 'Home',
  components: {
    ApprovedRequest,
    DeclinedRequest,
    PendingRequest,
    BCard,
    BTabs,
    BTab,
    BButton
  },
  directives: {
    Ripple,
  },

}
</script>
